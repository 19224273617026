import Modal from 'bootstrap/js/dist/modal';
import functions from '../functions';
import exchangeRate from '../exchangeRate';


const accBookingModal = {
    cart: null,
    triggerUnitData: null,
    $modal: null,
    modalInstance: null,
    cartData: [],
    checkedOptionals: [],
    init(cart) {
        accBookingModal.$modal = $('.accBookingModal');

        if (! accBookingModal.$modal.length) return;

        accBookingModal.modalInstance = new Modal(accBookingModal.$modal[0]);
        accBookingModal.cart = cart;
        accBookingModal.registerEvents();
        
    },
    handle(unitData) {
        if (! accBookingModal.$modal.length) return;

        accBookingModal.triggerUnitData = unitData;
        accBookingModal.cartData = [];

        accBookingModal.open();
        accBookingModal.sendRequest(accBookingModal.triggerUnitData);

    },
    open() {
        accBookingModal.modalInstance.show();
    },
    sendRequest(params) {
        let oldCurr = functions.getCookie('currencyIdTo');
        functions.setCookie('currencyIdTo', 1);

        params.language = functions.getLang();

        $.post('/services/booking_form/', params).then(response =>
        {
            functions.setCookie('currencyIdTo', oldCurr);
            if (response.status) 
            {
                let $html = $(response.html);
                $html.find('[data-price]').each((index, elem) => {
                    let $elem = $(elem);
                    let price = JSON.parse($elem.attr('data-price'));

                    $elem.find('.item-total-amount').text(exchangeRate.convertPrice(price) + ' ' + exchangeRate.getSymbol());
                })
                accBookingModal.$modal.find('.modal-body').html($html);

                accBookingModal.getCheckedOptionals();
            }
            
        });
    },
    registerEvents()
    {
        $(document).on('change', '.accBookingModal input[type="checkbox"]', e => {
            let id = $(e.currentTarget).prop('id');
            let checked = $(e.currentTarget).is(':checked') ? 1 : 0;
            let grouped = $(e.currentTarget).hasClass('grouped-checkbox');
            
            if (checked) {
                if (grouped)
                {
                    let ids = []
                    $('.accBookingModal .grouped-checkbox').each(function() {
                        ids.push(this.id);
                    });
                    accBookingModal.checkedOptionals = accBookingModal.checkedOptionals.filter(m => {
                        return ! ids.includes(m);
                    });

                }
                accBookingModal.checkedOptionals.push(id);
            }
            else
            {
                accBookingModal.checkedOptionals = accBookingModal.checkedOptionals.filter(m => {
                    return m !== id;
                });
            }

            accBookingModal.checkedOptionals = accBookingModal.checkedOptionals.filter(function(item, pos) {
                return accBookingModal.checkedOptionals.indexOf(item) == pos;
            })

            // accBookingModal.setCheckedOptionals();
        });
      
        $(document).on('change', '.accBookingModal input[type="checkbox"].grouped-checkbox',function() {

            $('.accBookingModal .grouped-checkbox').not(this).prop('checked', false);
        })

        $(document).on('change', '.accBookingModal .secondary-item input[type="checkbox"]', function (e) {
            let $row = $(e.currentTarget).closest('.calc-item');
            let $quantity = $row.find('[name=quantity]');
            let value = +$quantity.val();
            let checked = $(e.currentTarget).is(':checked') ? 1 : 0;

            if (checked && !value) {
                $quantity.prop('value', $quantity.find('option').eq(1).val() || 1);
            } else if (!checked && value) {
                $quantity.prop('value', 0);
            }

            let data  = JSON.parse($row.attr('data-calc'));

            let title = JSON.parse($row.attr('data-title'));
            let price = +JSON.parse($row.attr('data-price'));

            if (checked)
            {
                let unitData = {
                    'cartId': data['id'],
                    'unitId': data['unitId'],
                    'dateFrom': data['dateFrom'],
                    'dateTo': data['dateTo'],
                    'guests': '1,0,0',
                    'quantity': value || 1,
                    'addAllOptionals': 1,
                    'title': title || '',
                    'price': +(price * (value || 1)),
                    'parentId': accBookingModal.triggerUnitData['unitId'] || null
                };

                accBookingModal.cartData.push(unitData);
            }
            else
            {
                accBookingModal.cartData = accBookingModal.cartData.filter(function(item) {
                    return item['cartId'] != data['id'];
                })
            }
        });

        $(document).on('change', '.accBookingModal .secondary-item select.quantity', function (e) {
            let $row = $(e.currentTarget).closest('tr');
            let $checkbox = $row.find('input[type="checkbox"]');
            let value = +$(e.currentTarget).val();
            
            if (value && $checkbox.is(':checked')) {
                let data  = JSON.parse($row.attr('data-calc'));

                accBookingModal.cartData.forEach(function(row, index) {
                    if (row['cartId'] == data['id']) accBookingModal.cartData[index]['quantity'] = value || 1;
                })
            } else if (value && !$checkbox.is(':checked')) {
                $checkbox.trigger('click');
            } else if (!value && $checkbox.is(':checked')) {
                $checkbox.trigger('click');
            }

        });

        accBookingModal.$modal.find('.continue').on('click', e => {

            accBookingModal.cartData.unshift(accBookingModal.triggerUnitData);
            accBookingModal.cartData.forEach(function(unitData) {
                accBookingModal.cart.updateTotal(+(unitData['price'] || 0));
                accBookingModal.cart.addUnitToCart(unitData);
            });

            accBookingModal.cart.setCartLenght();
            accBookingModal.cart.showItemAddedMsg(accBookingModal.triggerUnitData);
            accBookingModal.cart.registerRemoveFromCartEvent();
            accBookingModal.setCheckedOptionals();
        })

        accBookingModal.$modal.find('.add-and-buy').on('click', e => {

            accBookingModal.cartData.unshift(accBookingModal.triggerUnitData);
            accBookingModal.cartData.forEach(function(unitData) {
                accBookingModal.cart.updateTotal(+(unitData['price'] || 0));
                accBookingModal.cart.addUnitToCart(unitData);
            });

            accBookingModal.cart.setCartLenght();
            accBookingModal.cart.registerRemoveFromCartEvent();
            accBookingModal.setCheckedOptionals();

            let $elem = $(e.currentTarget);
            var url = $elem.attr('data-href');
            window.location = url;
            
        })

    },
    getCheckedOptionals()
    {
        let checkedOptionals = functions.getCookie('checkedOptionals') || '';
        accBookingModal.checkedOptionals = checkedOptionals ? JSON.parse(checkedOptionals) : [];
    },
    setCheckedOptionals()
    {
        functions.setCookie('checkedOptionals', JSON.stringify(accBookingModal.checkedOptionals), 1);
    },


    
}

export default accBookingModal;