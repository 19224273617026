if (window.wp && window.wp.ajax)
{

    const { registerBlockType } = window.wp.blocks;
    const { __ } = window.wp.i18n;
    const { Fragment, useState } = window.wp.element;
    const { InspectorControls, PanelColorSettings, withColors, getColorClassName, InnerBlocks, CheckboxControl } = window.wp.blockEditor;

    const BlockWithColorSettings = (props) =>
    {
        const { textColor, setTextColor, isChecked, setChecked } = props;  // Props received from withColors
        let divClass;
        let divStyles = {};
        if (textColor != undefined)
        {
            if (textColor.class != undefined)
            {
                divClass = textColor.class;
            } else
            {
                divStyles.background = textColor.color;
            }
        }

        return (
            <Fragment>
                <InspectorControls>
                    <PanelColorSettings
                        title={__('Color settings')}
                        colorSettings={[
                            {
                                value: textColor.color,
                                onChange: setTextColor,
                                label: __('Background color'),
                                colors: [
                                    {
                                        name: 'warning',
                                        color: '#DBBC7B',
                                    },
                                    {
                                        name: 'warning light',
                                        color: '#FAF4E7',
                                    },
                                    {
                                        name: 'light',
                                        color: '#F1F1F1',
                                    },
                                    {
                                        name: 'muted',
                                        color: '#E2E2E2',
                                    },
                                    {
                                        name: 'white',
                                        color: '#fff'
                                    },
                                    {
                                        name: 'black',
                                        color: '#222'
                                    }
                                ]
                            },
                        ]}
                    />
                </InspectorControls>
                <div className={divClass} style={divStyles}>
                    <InnerBlocks />
                </div>
            </Fragment>
        );
    }

    registerBlockType('awp/colorsettings', {
        title: __('Color Settings Demo'),
        icon: 'carrot',
        category: 'common',
        attributes: {
            textColor: {
                type: 'string'
            },
            customTextColor: {
                type: 'string'
            },
        },
        edit: withColors({ textColor: 'color' })(BlockWithColorSettings),
        save: (props) =>
        {
            const { textColor, customTextColor } = props.attributes;
            let divClass;
            let divStyles = {};
            if (textColor != undefined)
            {
                divClass = getColorClassName('color', textColor);
            }
            if (customTextColor != undefined)
            {
                divStyles.background = customTextColor;
            }
            return (
                <div className={divClass} style={divStyles}>
                    <InnerBlocks.Content />
                </div>
            );
        }
    });

}