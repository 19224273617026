import functions from "./functions.js";
const recaptcaPublicKey = '6LeJSbInAAAAAEeWvkbvpow-junws70tsPvQuNFc';
$(function () {

    const $form = $('.contact-form');
    const form = $form[0];

    const lang = functions.getLang();
    let output = '';

    if (!form) {
        return;
    }
    $form.on('submit', e => {
        e.preventDefault();

        $('.submit-btn').addClass('d-none');
        $('.contact-spinner').removeClass('d-none');
        $('.contact-spinner').addClass('d-flex');

        let params = {};
        $.each($form.serializeArray(), function (i, field) {
            params[field.name] = field.value;
        });

        params['language'] = functions.getLang();

        //$output.html('').addClass('d-none');
        grecaptcha.ready(function () {
            grecaptcha.execute(functions.getRecaptchaKey(), { action: 'contact' }).then(function (token) {
                params['gRecaptchaToken'] = token;
                params = functions.getDataForUrl(params);
                $.post('/services/contact/', params).then(response => {
                    
                    // $('#contact-modal').modal('show');
                    $(".contact-response").html(response.data);

                    $('.submit-btn').removeClass('d-none');
                    $('.contact-spinner').removeClass('d-flex');
                    $('.contact-spinner').addClass('d-none');

                    setTimeout(() => { $(".contact-response").addClass('d-none') }, 3000);

                    if (response.status == true) {
                        form.reset();
                    }
                });
            });
        });

    });

});