if ( window.wp && window.wp.ajax ) {
    const { registerBlockType } = window.wp.blocks;

    const { 
        InspectorControls,
        InnerBlocks
    } = window.wp.editor;

    const { 
        PanelBody, 
        TextControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/tab-content', {
        // built-in attributes
        title: 'Tab Content',
        description: 'Tab Content',
        icon: 'slides',
        category: 'custom-tabs',
        example: {},

        // custom attributes
        attributes: {
            id: {
                type: 'string',
                default: '',
            },
        },

        edit ({ attributes, setAttributes }) {

            const {
                id,
            } = attributes;

            function onIdChange (id) {
                setAttributes({id: id});
            };

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Tab settings' }>
                        <p><strong>ID must be unique</strong></p>
                        <TextControl value={ id } onChange={ onIdChange }></TextControl>
                    </PanelBody>
                </InspectorControls>,
                <div class="tab-content" id={ "nav-tabContent-" + id}>
                    <InnerBlocks allowedBlocks={ [ 'custom-gutenberg/tab-pane' ] } template={ [ [ 'custom-gutenberg/tab-pane', {} ] ] } />
                </div>
            ]);
        },

        save ({ attributes }) {

            const {
                id,
            } = attributes;

            return ( 
                <div className="tab-content" id={ "nav-tabContent-" + id}>
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}