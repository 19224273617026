import functions from './functions';
import { list } from './list2';
import Slider from "./Slider";
// import {Select2} from "../components/select2";

$(() => {
    listAccommodation.init();
});

const listAccommodation = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    defaults: {
        _page: 1,
        perPage: 9,
        language: functions.getLang(),
        pool: '',
        parking: '',
        pets: '',
        internet: '',
        seaView: '',
        pool: '', 
        dateFrom: null,
        dateTo: null,
        destination: '',
        countryId: '',
        objectGroupId: 6,
        objectTypeId: '',
        guests: '0,0,0',
        tagCategoryId: null,
        sortBy: null,
        // hasArrival: 4,
    },
    params: {},
    isHome: false,
    $listItems: null,
    filterParams: {
        tagCategoryIds: null,
        categoryObject: null, 
        sortBy: null, 
        objectName: null, 
        distanceBeach: null, 
        distanceCenter: null, 
        pool: null, 
        parking: null, 
        pets: null, 
        internet: null,
        objectTypeId: null,
        heatedPool: null,
        hydromassageBathtub: null,
        grill: null,
        seaView: null,
        ac: null,
        satTV: null,
        dishWasher: null,
        washingMachine: null,
        babyCot: null,
        sauna: null,
    },
    mainFields: ['dateFrom', 'dateTo', 'destination', 'objectTypeId', 'persons', 'personsInObject', 'sortBy', '_page'],
    init() {

        list.init();

        if (!list.$form || list.defaultParams.objectGroupId != this.defaults.objectGroupId) return

        list.$form.on('submit', e => {
            e.preventDefault();
            if ( list.$body.length ) {
                list.onChange();
                list.toggleMobileForm(false);
                //list.closeModal();
            } else {
                // non ajax form
                let data = $.param(functions.getFormData(list.$form));
                let toHash = list.$form.hasClass('form-hash');
                let href = location.protocol + '//' + location.host + list.$form.attr('action');
                if ( data ) {
                    href += (href.indexOf('?') === -1 ? (toHash ? '#' : '?') : '&');
                }
                href += data;
                list.setLoadingMode();
                list.toggleFilters(false);
                location.href = href;
            }
        });

        $.map(list.defaultParams, (value, key) => {
            this.params[key] = this.defaults[key] = value;
        });

        if(! list.defaultParams.isHome) {

            this.getParams();
            functions.setFormData(list.form, this.params);

            if($('[name=guests]').val()) {
                this.params.guests = $('[name=guests]').val();
            }
            this.find(this.params);
        } else {
            functions.setFormData(list.form, this.defaults);
        }

        // list.$form.on('submit', e => {
        //     const $target = $(e.target);
        //     e.preventDefault();
        //     console.log('submitted')
        //     this.isHome = $target.attr('data-ishome') || this.isHome;

        //     if(this.isHome) {
        //         this.params.objectGroupId = $target.attr('data-objectgroupid');
        //         this.params.countryId = this.params.destination = $target.attr('data-countryid');
        //         this.params.perPage = $target.attr('data-perpage') || this.defaults.perPage;
        //         this.params = $.extend({}, this.defaults, functions.getFormData($target));
        //     } else {
        //         this.getParams();
        //         this.params = $.extend(this.params, functions.getFormData($target));
        //     }
        //     this.params._page = this.defaults._page;

        //     if(this.isHome) {
        //         this.params.objectGroupId = $target.attr('data-objectgroupid');
        //         this.params.tagCategoryId = $target.attr('data-tagcategoryid');
        //         this.params.objectTypeId = $target.attr('data-objecttypeid');

        //         let searchParams = {};
        //         $.map(this.params, (value, key) => {
        //             if(value) {
        //                 searchParams[key] = value;
        //             }
        //         });
        //         location = $target.attr('data-listurl') + '#' + $.param(searchParams);
               
        //     } else {
        //         functions.setUrlData(this.params, true, true);
        //     }

        // });

        $('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();

            this.params.destination = '';

            const $form = $(e.currentTarget).parents('.search-form');
            const form = $form[0];

            $(form.destination).val('').trigger('change');
        });

        $(document).on('change', '[name="tagCategoryId"]', e => {
            // this.params._page = this.defaults._page;
            // this.getParams();
            this.setTagCategoryId();
            this.params._page = 1;
            // this.find(this.params);

            functions.setUrlData(this.params, true, true);
        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.stopChangeEvents = true;
            this.setTagCategoryId();
            functions.scrollToElement(list.$list, 80);
            this.find(this.params);
            
            this.stopChangeEvents = false;
       
        });

    },
    setTagCategoryId() {
        let tagCategoryIds = [];
        $('[name="tagCategoryId"]').each((index, target) => {
            const $target = $(target);
            if($target[0].checked) {
                tagCategoryIds.push($target.val());
            }
        });
        this.params.tagCategoryId = tagCategoryIds.join(",");
       
    },
    getParams() {
        this.params = list.getParams(this.params, this.defaults);
        if(this.params.dates) {
            this.splitCalendarDates(this.params.dates);
        }

        if(this.params.arrivalDateFrom) {
            
            let dateFrom = new Date(this.params.arrivalDateFrom);
            let month = dateFrom.getMonth() + 1;
            
            let lastDateOfMonth = new Date(dateFrom.getFullYear(), month, 0);
            this.params.arrivalDateTo = lastDateOfMonth.getFullYear() + '-' + (lastDateOfMonth.getMonth() + 1) + '-' + lastDateOfMonth.getDate();

            if ( this.params.objectTypeId && this.params.objectTypeId == 49 ) this.params.hasArrival = null;
        }

        this.params.guests = this.params.guests.startsWith('0') ? null : this.params.guests;
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
      
    },
    find(params) {
        list.find(params).then((response) => {
            

            list.findDone(response, this.params);
            window.observer.observe();
            list.domInits();

            this.$category = $('[name="tagCategoryId"]');
            this.tagCategoryId = this.$category[0] ? this.$category[0] : null;

            if(this.tagCategoryId && this.params.tagCategoryId) {
                let tags = this.params.tagCategoryId.split(',');
                this.$category.each((index, elem) => {
                    if(elem.type === 'checkbox') {
                        elem.checked = tags.includes(elem.value);
                    } else if(elem.type === 'select-one') {
                        $(elem).val(this.params.tagCateroyId);
                    }
                });
            }
        }).fail(error => {
            console.log(error);
        });
    },
    initComponents() {
        let listSlider = [];

        $('[id^=card-slider]').each((index, element) => {
            listSlider[index] = new Slider('#' + $(element).attr('id'), {
                rubberband: false,
                loop: true,
                arrows: true,
                slides: {
                    perView: 1,
                    spacing: 0,
                },
            });
        });

        
        this.$filter = $('.search-filter');
        this.filter = this.$filter[0];
        
        this.$filter.find('.select2').each((i, elem) => {
            elem._select2 = new Select2(elem);
        });

        if(this.filter) {
            functions.setFormData(this.filter, this.params);
        }

        this.$filter.on('submit', e => {
            const $target = $(e.currentTarget);
            e.preventDefault();
            e.stopPropagation();

            if ( this.stopChangeEvents ) {
                return;
            }
            
            this.params = $.extend(this.params, this.filterParams, functions.getFormData($target));
            this.params._page = this.defaults._page;
            functions.setUrlData(this.params, true);
        });

        $('.btn-clear-filters').on('click', e => {
            e.preventDefault();
            this.params = $.extend(this.params, this.filterParams);
            this.params._page = this.defaults._page;
            functions.setUrlData(this.params, true);
            functions.setFormData(this.filter, this.params);
        });
    }
};