if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
        InnerBlocks
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl,
        Button
    } = window.wp.components;

    registerBlockType('custom-gutenberg/card-banner-horizontal', {
        // built-in attributes
        title: 'Card Banner Horizontal',
        description: 'Custom Card Banner Horizontal',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            image: {
                type: 'string',
                default: ''
            }, 
            alt: {
                type: 'string',
                default: ''
            }, 
            tag: {
                type: 'string',
                default: ''
            },
            title: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: 'Pogledajte ponudu'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                link,
                image,
                alt,
                tag,
                title,
                description,
                viewMore
            } = attributes;

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({image: image.sizes.full.url});
            }

            function onAltChange(alt) {
                setAttributes({alt: alt});
            }

            function onTagChange(tag) {
                setAttributes({tag: tag});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            function onViewMoreChange(viewMore) {
                setAttributes({viewMore: viewMore});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ image } 
                            render={ ( { open } ) => (
                                <div>
                                    { image && 
                                        <div>
                                            <figure>
                                                <img src={image} />
                                            </figure>
                                            <div>
                                                <Button onClick={() => setAttributes({ image: '' })} className="button">Remove</Button>
                                            </div>
                                        </div>
                                    }
                                    
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                                </div>
                            ) } />
                        <br />
                        <p><strong>Alt image:</strong></p>
                        <TextControl value={ alt } onChange={ onAltChange }></TextControl>
                        <br />
                        <p><strong>Enter url: (ex: /events)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <a class="card card-banner card-banner-horizontal has-animation">
                    <div class="card-img-top">
                        <img className="cover-image" src={ image } alt={ alt } />
                    </div>
                    <div class="card-body">
                        <InnerBlocks />
                    </div>
                </a>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                image,
                alt,
            } = attributes;

            let elemType = 'div';

            if (link) elemType = 'a';

            const ElemTag = `` + elemType + ``;

            return (
                <ElemTag href={ link } className="card card-banner card-banner-horizontal has-animation">
                    <div className="card-img-top">
                        <img className="lozad cover-image" data-src={ image } alt={ alt } />
                    </div>
                    <div className="card-body">
                        <InnerBlocks.Content />
                    </div>
                </ElemTag>
            );
        },
    });
}