if (window.wp && window.wp.ajax)
{

    const { registerBlockType } = window.wp.blocks;

    const {
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;

    const {
        PanelBody,
        IconButton,
        TextControl,
        Button
    } = window.wp.components;

    registerBlockType('custom-gutenberg/card-banner-small', {
        // built-in attributes
        title: 'Card Banner Small',
        description: 'Custom Card Banner Small',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            image: {
                type: 'string',
                default: ''
            },
            alt: {
                type: 'string',
                default: ''
            },
            title: {
                type: 'string',
                default: ''
            },
            subtitle: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes })
        {

            const {
                link,
                image,
                alt,
                title,
                subtitle,
            } = attributes;

            // custom functions
            function onLinkChange(link)
            {
                setAttributes({ link: link });
            };

            function onSelectImage(image)
            {
                setAttributes({ image: image.sizes.full.url });
            }

            function onAltChange(alt)
            {
                setAttributes({ alt: alt });
            }

            function onTitleChange(title)
            {
                setAttributes({ title: title });
            }

            function onSubtitleChange(subtitle)
            {
                setAttributes({ subtitle: subtitle });
            }

            return ([
                <InspectorControls style={{ marginBottom: '40px' }}>
                    <PanelBody title={'Settings'}>
                        <MediaUpload
                            onSelect={onSelectImage}
                            type="image"
                            value={image}
                            render={({ open }) => (
                                <div>
                                    { image && 
                                        <div>
                                            <figure>
                                                <img src={image} />
                                            </figure>
                                            <div>
                                                <Button onClick={() => setAttributes({ image: '' })} className="button">Remove</Button>
                                            </div>
                                        </div>
                                    }
                                    
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                                </div>
                            )} />
                        <br />
                        <p><strong>Alt image:</strong></p>
                        <TextControl value={alt} onChange={onAltChange}></TextControl>
                        <br />
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={link} onChange={onLinkChange}></TextControl>
                        <br />
                        <p><strong>Title</strong></p>
                        <TextControl value={title} onChange={onTitleChange}></TextControl>
                        <br />
                        <p><strong>Subtitle</strong></p>
                        <TextControl value={subtitle} onChange={onSubtitleChange}></TextControl>
                        <br />
                    </PanelBody>
                </InspectorControls>
                ,
                <a class="card card-banner card-banner-large wp-block-custom-gutenberg-card-banner-small">
                    <div class="card-img-top">
                        <img class="cover-image" src={image} alt={alt} />
                    </div>
                    <div class="card-body">
                        <div class="title-container">
                            <RichText
                                key="editable"
                                tagName="h4"
                                className="title"
                                placeholder="TITLE"
                                value={title}
                                onChange={onTitleChange} />
                        </div>
                        <div class="title-container">
                            <RichText
                                key="editable"
                                tagName="h4"
                                className="subtitle"
                                placeholder="SUBTITLE"
                                value={subtitle}
                                onChange={onSubtitleChange} />
                        </div>
                    </div>
                </a>
            ]
            );
        },

        save({ attributes })
        {

            const {
                link,
                image,
                alt,
                title,
                subtitle,
            } = attributes;

            let elemType = 'div';

            if (link) elemType = 'a';

            const ElemTag = `` + elemType + ``;

            return (
                <ElemTag href={link} className="card lozad cover-image" data-background-image={image} alt={alt}>
                    <div className="card-body">
                        <h2 className="card-title">
                            {title}
                        </h2>
                        <h3 className="card-subtitle mt-1">
                            {subtitle}
                        </h3>
                    </div>
                </ElemTag>
            );
        },
    });
}