if (window.wp && window.wp.ajax)
{

    const { registerBlockType } = window.wp.blocks;

    const {
        RichText,
        InspectorControls,
    } = window.wp.editor;

    const {
        PanelBody,
        SelectControl,
        TextControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/title-section', {
        // built-in attributes
        title: 'Title section',
        description: 'Custom Title section',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            title: {
                type: 'string',
                default: ''
            },
            subtitle: {
                type: 'string',
                default: ''
            },
            text: {
                type: 'string',
                default: ''
            },
            headingTag: {
                type: 'string',
                default: 'h2'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes })
        {

            const {
                title,
                subtitle,
                text,
                headingTag
            } = attributes;

            // custom functions
            function onTitleChange(title)
            {
                setAttributes({ title: title });
            }

            function onSubtitleChange(subtitle)
            {
                setAttributes({ subtitle: subtitle });
            }

            function onTextChange(text)
            {
                setAttributes({ text: text });
            }

            return ([
                <InspectorControls style={{ marginBottom: '40px' }}>
                    <PanelBody title={'Content'}>
                        <p><strong>Title</strong></p>
                        <TextControl value={title} onChange={onTitleChange}></TextControl>

                        <p><strong>Subtitle</strong></p>
                        <TextControl value={subtitle} onChange={onSubtitleChange}></TextControl>

                        <p><strong>Text</strong></p>
                        <TextControl value={text} onChange={onTextChange}></TextControl>

                        <p><strong>Heading type</strong></p>
                        <SelectControl
                            label="Heading type"
                            value={headingTag}
                            options={ [
                                {
                                    value: 'h1',
                                    label: 'Heading 1',
                                },
                                {
                                    value: 'h2',
                                    label: 'Heading 2',
                                },
                                {
                                    value: 'h3',
                                    label: 'Heading 3',
                                },
                                {
                                    value: 'h4',
                                    label: 'Heading 4',
                                },
                                {
                                    value: 'h5',
                                    label: 'Heading 5',
                                },
                                {
                                    value: 'h6',
                                    label: 'Heading 6',
                                }
                            ]}
                            onChange={ ( newSize ) => setAttributes({'headingTag': newSize}) }
                        />

                    </PanelBody>
                </InspectorControls>
                ,
                <div class="container">
                    <div class="row position-relative text-center content-title-section">
                        <div class="col-12">
                            <i class="las la-landmark display-6 text-warning mb-1"></i>
                            <RichText
                                key="editable"
                                tagName="h2"
                                className="section-title"
                                placeholder="TITLE"
                                value={title}
                                onChange={onTitleChange} />
                            <RichText
                                key="editable"
                                tagName="h3"
                                className="section-subtitle"
                                placeholder="SUBTITLE"
                                value={subtitle}
                                onChange={onSubtitleChange} />
                            <RichText
                                key="editable"
                                tagName="p"
                                className="section-text"
                                placeholder="TEXT"
                                value={text}
                                onChange={onTextChange} />

                        </div>
                    </div>
                </div>
            ]
            );
        },

        save({ attributes })
        {

            const {
                title,
                subtitle,
                text,
                headingTag
            } = attributes;

            const CustomTag = `` + headingTag + ``;

            return (
                <div className="container">
                    <div className="row position-relative text-center content-title-section">
                        <div className="col-12">
                            <i className="las la-landmark display-6 text-warning mb-1"></i>
                            <CustomTag className="section-title">
                                { title }
                            </CustomTag>
                            <h3 className="section-subtitle">
                                { subtitle }
                            </h3>
                            {
                                text ? (
                                    <p className="section-text">{ text }</p>
                                ) : ('')
                            }
                        </div>
                    </div>
                </div>
            );
        },
    });
}