import functions from "./functions";

const exchangeRate = {
    rate: 1,
    id: 1,
    symbol: '€',
    init(_callback) {
        this.id = functions.getCookie('currencyIdTo');

        if ( ! this.id || this.id == 1)
        {
            _callback();
            return;
        }

        let key = 'curr' + this.id;
        let cookieRate = functions.getCookie(key);
        if (cookieRate)
        {
            cookieRate = JSON.parse(cookieRate);
            this.rate = +cookieRate.rate;
            this.symbol = cookieRate.symbol;
            _callback();
        }
        else
        {
            $.get('/services/exchangeRate/', {currencyIdTo : this.id}).then(res => {
                
                res = JSON.parse(res);
                if (res.rate)
                {   
                    this.rate = +res.rate;
                    this.symbol = res.symbol;
    
                    let expires = new Date();
                    expires.setHours(23,59,59,0);
                    document.cookie = key + '=' + JSON.stringify({rate: this.rate, symbol: this.symbol}) + ';expires=' + expires.toUTCString() + '; path=/';
                }
                _callback();
            });
        }

        
    },
    getExchangeRate() {
        return this.rate;
    },
    convertPrice(price, language) {
        if (this.id == 1) return functions.formatMoney(price, 2, '.', ',');

        return functions.formatMoney(price / this.rate, 2, '.', ',');
    },
    getSymbol()
    {
        if (this.id == 1) return '€';
        return this.symbol;
    }
}

export default exchangeRate;