if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        TextControl,
    } = window.wp.components;

    let text = 'View more';

    registerBlockType('custom-gutenberg/view-more', {
        // built-in attributes
        title: 'View More',
        description: 'View More',
        icon: '',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            text: {
                type: 'string',
                default: 'View more'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                text,
            } = attributes;


            function onTextChange(text) {
                setAttributes({text: text});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <p><strong>Text</strong></p>
                        <TextControl value={ text } onChange={ onTextChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <div class="view-more d-flex justify-content-center align-items-center ">
                    <RichText 
                        key="editable" 
                        tagName="span"
                        placeholder="ViewMore" 
                        value={ text }
                        onChange={ onTextChange } />
                    <div class=""> 
                        <i class="las la-angle-right la-lg"></i> 
                    </div>
                </div>
            ]
            );
        },

        save({ attributes }) {

            const {
                text,
            } = attributes;

            return (
                <div class="view-more d-flex justify-content-center align-items-center ">
                    <div class="">
                       {text}
                    </div> 
                    <div class=""> 
                        <i class="las la-angle-right la-lg"></i> 
                    </div> 
                </div>
            );
        },
    });
}