if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl,
        TextareaControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/button-outline', {
        // built-in attributes
        title: 'Button Outline',
        description: 'Button Outline',
        icon: '',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            text: {
                type: 'string',
                default: 'CELOKUPNA PONUDA'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                link,
                text,
            } = attributes;

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };


            function onTextChange(text) {
                setAttributes({text: text});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                        <br />
                        <p><strong>Text</strong></p>
                        <TextControl value={ text } onChange={ onTextChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <a class="button-outline btn btn-outline-dark">
                    <RichText 
                        key="editable" 
                        tagName="span"
                        placeholder="CELOKUPNA PONUDA" 
                        value={ text }
                        onChange={ onTextChange } />
                </a>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                text,
            } = attributes;

            return (
                <a className="button-outline btn btn-outline-dark" href={ link }>
                    { text }
                </a>
            );
        },
    });
}