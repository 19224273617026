if (window.wp && window.wp.ajax)
{

    const { registerBlockType } = window.wp.blocks;

    const {
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;

    const {
        PanelBody,
        IconButton,
        TextControl,
        Button
    } = window.wp.components;

    registerBlockType('custom-gutenberg/hero-slide', {
        // built-in attributes
        title: 'Hero Slide',
        description: 'Custom Hero Slide',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            backgroundImage: {
                type: 'string',
                default: ''
            },
            title: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            period: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: 'FIND OUT MORE'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes })
        {

            const {
                link,
                backgroundImage,
                title,
                description,
                period,
                viewMore
            } = attributes;

            // custom functions
            function onLinkChange(link)
            {
                setAttributes({ link: link });
            };

            function onSelectImage(image)
            {
                setAttributes({ backgroundImage: image.sizes.full.url });
            }

            function onTitleChange(title)
            {
                setAttributes({ title: title });
            }

            function onDescriptionChange(description)
            {
                setAttributes({ description: description });
            }

            function onViewMoreChange(viewMore)
            {
                setAttributes({ viewMore: viewMore });
            }

            function onPeriodChange(period)
            {
                setAttributes({ period: period });
            }

            return ([
                <InspectorControls style={{ marginBottom: '40px' }}>
                    <PanelBody title={'Link'}>
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={link} onChange={onLinkChange}></TextControl>
                    </PanelBody>
                    <PanelBody title={'Background Image Settings'}>
                        <MediaUpload
                            onSelect={onSelectImage}
                            type="image"
                            value={backgroundImage}
                            render={({ open }) => (
                                <div>
                                    { backgroundImage && 
                                        <div>
                                            <figure>
                                                <img src={backgroundImage} />
                                            </figure>
                                            <div>
                                                <Button onClick={() => setAttributes({ backgroundImage: '' })} className="button">Remove</Button>
                                            </div>
                                        </div>
                                    }
                                    
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                                </div>
                            )} />
                    </PanelBody>
                    <PanelBody title={'Slide Content'}>
                        <p><strong>Title</strong></p>
                        <TextControl value={title} onChange={onTitleChange}></TextControl>
                        <p><strong>Subtitle</strong></p>
                        <TextControl value={description} onChange={onDescriptionChange}></TextControl>
                        <p><strong>Period</strong></p>
                        <TextControl value={period} onChange={onPeriodChange}></TextControl>
                        <p><strong>Button</strong></p>
                        <TextControl value={viewMore} onChange={onViewMoreChange}></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <div class="keen-slider__slide">
                    <div class="cover-image" style={{ height: 350, backgroundImage: `url(${backgroundImage})` }}>
                        <div class="container">
                            <a class="content">
                                <RichText
                                    key="editable"
                                    tagName="div"
                                    className="description"
                                    placeholder="DESCRIPTION"
                                    value={description}
                                    onChange={onDescriptionChange} />
                                <RichText
                                    key="editable"
                                    tagName="h2"
                                    className="title"
                                    placeholder="Title"
                                    value={title}
                                    onChange={onTitleChange} />
                                <div class="cta">
                                    <RichText
                                        key="editable"
                                        tagName="div"
                                        className="cta-content"
                                        placeholder="CONTENT"
                                        value={viewMore}
                                        onChange={onViewMoreChange} />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            ]
            );
        },

        save({ attributes })
        {
            const {
                link,
                backgroundImage,
                title,
                description,
                period,
                viewMore
            } = attributes;

            return (
                <div className="cover-image home-hero-slide lozad keen-slider__slide" data-background-image={backgroundImage}>
                    <div className="container slider-content">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="subtitle">{description}</div>
                                <h2 className="title">{title}</h2>
                                <h3 className="period">{period}</h3>
                                <a href={link} className="cta">
                                    <div className="btn btn-primary rounded-pill px-lg-4 view-more-btn">
                                        {viewMore}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    });
}