if (window.wp && window.wp.ajax)
{

    const { registerBlockType } = window.wp.blocks;

    const {
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;

    const {
        PanelBody,
        IconButton,
        TextControl,
        TextareaControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/card-banner-large', {
        // built-in attributes
        title: 'Card Banner Large',
        description: 'Custom Card Banner Large',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            image: {
                type: 'string',
                default: ''
            },
            alt: {
                type: 'string',
                default: ''
            },
            title: {
                type: 'string',
                default: ''
            },
            subtitle: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: 'View more'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes })
        {

            const {
                link,
                image,
                alt,
                title,
                subtitle,
                description,
                viewMore
            } = attributes;

            // custom functions
            function onLinkChange(link)
            {
                setAttributes({ link: link });
            };

            function onSelectImage(image)
            {
                setAttributes({ image: image.sizes.full.url });
            }

            function onAltChange(alt)
            {
                setAttributes({ alt: alt });
            }

            function onTitleChange(title)
            {
                setAttributes({ title: title });
            }

            function onSubtitleChange(subtitle)
            {
                setAttributes({ subtitle: subtitle });
            }

            function onDescriptionChange(description)
            {
                setAttributes({ description: description });
            }

            function onViewMoreChange(viewMore)
            {
                setAttributes({ viewMore: viewMore });
            }

            return ([
                <InspectorControls style={{ marginBottom: '40px' }}>
                    <PanelBody title={'Settings'}>
                        <MediaUpload
                            onSelect={onSelectImage}
                            type="image"
                            value={image}
                            render={({ open }) => (
                                <IconButton
                                    onClick={open}
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            )} />
                        <br />
                        <p><strong>Alt image:</strong></p>
                        <TextControl value={alt} onChange={onAltChange}></TextControl>
                        <br />
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={link} onChange={onLinkChange}></TextControl>
                        <br />
                        <p><strong>Title</strong></p>
                        <TextControl value={title} onChange={onTitleChange}></TextControl>
                        <br />
                        <p><strong>Subtitle</strong></p>
                        <TextControl value={subtitle} onChange={onSubtitleChange}></TextControl>
                        <br />
                        <p><strong>Description</strong></p>
                        <TextareaControl value={description} onChange={onDescriptionChange}></TextareaControl>
                        <br />
                        <p><strong>View more</strong></p>
                        <TextControl value={viewMore} onChange={onViewMoreChange}></TextControl>

                    </PanelBody>
                </InspectorControls>
                ,
                <a class="card card-banner card-banner-large">
                    <div class="card-img-top">
                        <img class="cover-image" src={image} alt={alt} />
                    </div>
                    <div class="card-body">
                        <RichText
                            key="editable"
                            tagName="div"
                            className="description"
                            placeholder="DESCRIPTION"
                            value={description}
                            onChange={onDescriptionChange} />
                        <div class="title-container">
                            <RichText
                                key="editable"
                                tagName="h4"
                                className="title"
                                placeholder="TITLE"
                                value={title}
                                onChange={onTitleChange} />
                        </div>
                        <div class="title-container">
                            <RichText
                                key="editable"
                                tagName="h4"
                                className="subtitle"
                                placeholder="SUBTITLE"
                                value={subtitle}
                                onChange={onSubtitleChange} />
                        </div>
                        <div class="cta">
                            <RichText
                                key="editable"
                                tagName="div"
                                className="cta-content"
                                placeholder="View more"
                                value={viewMore}
                                onChange={onViewMoreChange} />
                        </div>
                    </div>
                </a>
            ]
            );
        },

        save({ attributes })
        {

            const {
                link,
                image,
                alt,
                title,
                subtitle,
                description,
                viewMore
            } = attributes;

            return (
                <a href={link} className="card large lozad cover-image" data-background-image={image} alt={alt}>
                    <div className="card-body">
                        <div className="row position-relative text-center">
                            <div className="col-12">
                                <i className="las la-landmark display-6 text-warning mb-1"></i>
                                <h2 className="card-title">
                                    {title}
                                </h2>
                                <h3 className="card-subtitle mb-3 mt-1">
                                    {subtitle}
                                </h3>
                                <p className="card-text">
                                    {description}
                                </p>
                                <div className="view-more d-flex justify-content-center align-items-baseline">
                                    <div className="">{ viewMore }</div>
                                    <i className="las la-angle-right"></i>

                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            );
        },
    });
}