import functions from "./functions.js";
const calendar = {
    availabilityMask: null,
    minStay: null,
    translations: null,
    containerClass: null,
    today: null,
    yesterday: null,
    config: {},
    defaults: {
        availabilityMask: '',
        startDate: '',
        minStay: '',
        translations: {},
        containerClass: '',
        dateFormat: "Y-m-d",
        showMonths: 4,
        nextArrow: '<i class="las la-arrow-right la-lg"></i>',
        prevArrow: '<i class="las la-arrow-left la-lg"></i>',

        checkOutClass: 'checkOutAllowed',
        checkOutOnlyClass: 'checkOutOnlyAllowed',
        checkInClass: 'checkInAllowed',
        notAvailableClass: 'notAvailable',
        notArrivalDepartureClass: 'disabled',
        notMinStayClass: 'disabled',

        rangeSeparator: ' - ',

        defaultDate: [],
        minDate: 'today',
        inline: true,
        altInput: true,
        wrap: false,
        altFormat: "j.m.Y",
    },

    init($elem, config, callback) {

        calendar.config = $.extend({}, calendar.defaults, config);
        calendar.config.minStay = calendar.config.minStay.split(',');

        calendar.config.startDate = new Date(calendar.config.startDate);
        calendar.config.startDate.setHours(0,0,0,0);

        calendar.today = new Date();
        calendar.today.setHours(0,0,0,0);

        calendar.yesterday = new Date(calendar.today.getTime());
        calendar.yesterday.setDate(calendar.yesterday.getDate() - 1);

        if (+calendar.config.startDate != +calendar.today)
        {
            let diff = calendar.dateDiff(calendar.today, calendar.config.startDate);
            calendar.config.availabilityMask = '' + calendar.config.availabilityMask.slice(diff);
        }

        if ( window.innerWidth < 1350 ) {
            calendar.config.showMonths = 2;
        }
        if ( functions.isMobile() ) {
            calendar.config.showMonths = 1;
        }
        let flatpickr = $elem.flatpickr({

            altInput: calendar.config.altInput,
            wrap: calendar.config.wrap,
            altFormat: calendar.config.altFormat,
            dateFormat: calendar.config.dateFormat,
            disableMobile: true,
            mode: calendar.config.mode,
            minRange: 0,
            showMonths: calendar.config.showMonths,
            inline: calendar.config.inline,
            defaultDate: calendar.config.defaultDate,
            minDate: calendar.config.minDate,
            onDayCreate: function (dObj, dStr, fp, dayElem) {

                let status = calendar.getStatus(dayElem.dateObj);
                if (status == 'N')
                {
                    calendar.setDayNonAvailable(dayElem);
                    return;
                }
                else if (status == 'A')
                {

                }
                else if (status == 'S')
                {
                    calendar.setDayNotArrivalPossible(dayElem);
                }
                else if (status == 'D')
                {
                    calendar.setDayCheckOutOnlyAvailable(dayElem);
                }
                else if (status == 'I')
                {
                    dayElem.className += ' ' + calendar.config.checkInClass;
                }
                else if (status == 'O')
                {
                    calendar.setDayCheckOutAvailable(dayElem);
                }

                let onlyCheckInSelected = fp.selectedDates[0] && ! fp.selectedDates[1];

                if (onlyCheckInSelected && calendar.config.mode != 'single') {

                    // CHECK MIN STAY
                    let minStay = calendar.getMinStay(fp.selectedDates[0]);

                    if (minStay) {
                        // get difference between selected and current date of the loop
                        // check if min stay is met - skip selected date
                        let diff = calendar.dateDiff(dayElem.dateObj, fp.selectedDates[0]);
                        if (Math.abs(diff) < minStay) {
                            calendar.setDayNotMinStay(dayElem, diff, minStay);
                        }
                    }

                    fp.calendarContainer.classList.add('selection');

                    if (+dayElem.dateObj != +fp.selectedDates[0] && ! calendar.isAvailable(dayElem.dateObj, fp.selectedDates[0])) {
                        calendar.setDayNotAllowedRange(dayElem);
                        return;
                    }

                    if (dayElem.dateObj.getTime() < fp.selectedDates[0].getTime()) {
                        calendar.setDayNotAllowedRange(dayElem);
                    } else {
                        calendar.removeNotAllowedRange(dayElem);
                    }

                } else {
                    fp.calendarContainer.classList.remove('selection');
                }
            },
            onChange: function (selectedDates, dateStr, instance) {
                callback(calendar.getDateFrom(selectedDates), calendar.getDateTo(selectedDates));
            },
            onReady: function (selectedDates, dateStr, instance) {

                if (calendar.config.containerClass) {
                    let classList = instance.calendarContainer.classList;
                    let classes = calendar.config.containerClass.split(' ');
                    classes.forEach(function (css_class, index) {
                        classList.add(css_class);
                    });
                }
            },

            nextArrow: calendar.config.nextArrow,
            prevArrow: calendar.config.prevArrow,
            locale: {
                rangeSeparator: calendar.config.rangeSeparator
            }
        });

        return flatpickr;
    },
    getDateFrom: function (selectedDates) {
        if ( ! selectedDates[0]) return null;
        if ( ! selectedDates[1]) return selectedDates[0];

        return selectedDates[0] < selectedDates[1] ? selectedDates[0] : selectedDates[1];
    },
    getDateTo: function (selectedDates) {
        if ( ! selectedDates[1]) return null;

        return selectedDates[0] < selectedDates[1] ? selectedDates[1] : selectedDates[0];
    },
    isAvailable(from, to) {

        if ( ! to) {
            to = calendar.addDate(calendar.cleanDate(from), 1);
        }

        let dateFrom = from < to ? from : to;
        let dateTo = to > from ? to : from;

        if (dateFrom < calendar.yesterday) {
            return false;
        }

        if ( ! calendar.config.availabilityMask) return false;

        let diff = calendar.dateDiff(dateFrom, calendar.today);
        let dateToDiff = (diff + calendar.dateDiff(dateTo, dateFrom));
        let mask = '' + calendar.config.availabilityMask.substring(diff, dateToDiff);

        return mask ? mask.indexOf('N') === -1 : false;
    },
    getStatus(from, to) {

        if ( ! to) {
            to = calendar.addDate(calendar.cleanDate(from), 1);
        }

        let dateFrom = from < to ? from : to;
        let dateTo = to > from ? to : from;

        if (dateFrom <= calendar.yesterday) {
            return 'N';
        }

        if ( ! calendar.config.availabilityMask) return 'N';

        let diff = calendar.dateDiff(dateFrom, calendar.today);
        let dateToDiff = (diff + calendar.dateDiff(dateTo, dateFrom));
        let mask = '' + calendar.config.availabilityMask.substring(diff, dateToDiff);

        return mask;
    },
    addDate: function (date, days) {
        date = calendar.cleanDate(date);
        date.setDate(date.getDate() + days)
        return date;
    },
    formatDate(date) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        let d = new Date(date);
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('.')
    },
    dateDiff(from, to) {
        let f = Date.UTC(from.getFullYear(), from.getMonth()+1, from.getDate());
        let t = Date.UTC(to.getFullYear(), to.getMonth()+1, to.getDate());

        return Math.round((from - to) / (1000 * 60 * 60 * 24));
    },
    setDayCheckOutAvailable(dayElem) {
        dayElem.className += ' ' + calendar.config.checkOutClass;
    },
    setDayCheckOutOnlyAvailable(dayElem) {
        dayElem.className += ' ' + calendar.config.checkOutOnlyClass;
    },
    setDayNonAvailable(dayElem) {

        dayElem.className += ' ' + calendar.config.notAvailableClass;

        $(dayElem).tooltip({
            title: calendar.config.translations.not_available || ''
        });
    },
    setDayNotArrivalPossible(dayElem) {
        dayElem.className += ' flatpickr-disabled ' + calendar.config.notArrivalDepartureClass;
        $(dayElem).tooltip({
            title: calendar.config.translations.not_arrival_day || ''
        });
    },
    setDayNotDeparturePossible(dayElem) {
        dayElem.className += ' flatpickr-disabled ' + calendar.config.notArrivalDepartureClass;
        $(dayElem).tooltip({
            title: calendar.config.translations.not_departure_day || ''
        });
    },
    setDayNotMinStay(dayElem, diff, minStay) {
        dayElem.className += ' flatpickr-disabled ' + calendar.config.notMinStayClass;
        if (diff) {
            $(dayElem).tooltip({
                title: calendar.config.translations.min_stay_is && calendar.config.translations.min_stay_is.replace('{$min_stay}', minStay) || ''
            });
        }
    },
    setDayNotAllowedRange(dayElem) {

        dayElem.className += ' notAllowedRange';

        $(dayElem).tooltip({
            title: ''
        });
    },
    removeNotAllowedRange(dayElem) {
        dayElem.className = dayElem.className.replace(' notAllowedRange', '');
    },
    getMinStay(date) {

        if ( ! calendar.config.minStay.length) return 0;

        let diff = calendar.dateDiff(date, calendar.today);

        return calendar.config.minStay[diff] || 0;

    },
    cleanDate(date) {
        date = new Date(date);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }
}
module.exports = {
    calendar: calendar
};