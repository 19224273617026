import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const {useBlockProps, useInnerBlocksProps } = window.wp.blockEditor;

    const { 
        InnerBlocks,
    } = window.wp.editor;

    const ALLOWED_BLOCKS = ['custom-gutenberg/column'];

    registerBlockType('custom-gutenberg/row', {
        // built-in attributes
        title: 'Row',
        description: 'Custom row',
        icon: '',
        category: 'custom-containers',
        example: {},

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                classes
            } = attributes;


            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            const blockProps = useBlockProps( {} );
            const innerBlocksProps = useInnerBlocksProps( blockProps, {} );

            const MY_TEMPLATE = [
                [ 'custom-gutenberg/column', {} ],
            ];

            innerBlocksProps.className = 'row ' + classes;
            
            return (
                innerBlocksProps.children
            );
        },

        save({ attributes }) {

            const {
                classes
            } = attributes;

            return (
                <div className={ "row " + (classes ? ' ' + classes : '') }>
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}