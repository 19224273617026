import exchangeRate from "../exchangeRate";
import functions from "../functions";
import accBookingModal from "./accBookingModal";


const cart = {
    cartData: [],
    uxCartData: [],
    $bookingBtns: null,
    $cartLengthBtns: null,
    $cart: null,
    $cartBody: null,
    $cartItemCopy: null,
    $itemAddedMsg: null,
    $continueToPayment: null,
    cartTotal: 0,
    $total: null,
    init() 
    {
        cart.getCartData();

        cart.$bookingBtns = $('.cart-book');
        cart.$cartLengthBtns = $('.cart-length');
        cart.$cart = $('.cart');
        
        if (! cart.$cart.length) return;

        accBookingModal.init(cart);

        cart.$cartBody = cart.$cart.find('.card-body');
        cart.$cartItemCopy = cart.$cartBody.find('.cart-item-copy').first();
        cart.$total = cart.$cart.find('.total');
        cart.$itemAddedMsg = $('.cart-message');
        cart.$continueToPayment = cart.$cart.find('.cart-payment');

        cart.setCartLenght();
        cart.renderCart();
        cart.registerRemoveFromCartEvent();

        // cart.setBookBtnUrl();

        this.$cart.find('.close-cart').on('click', e => {
            let $elem = $(e.currentTarget);
            let $dropdown = $elem.parents('.dropdown');
            $dropdown.find('.cart-btn').first().click();

        })

        cart.$itemAddedMsg.on('click', e => {
            if (! $(e.target).hasClass('cart-book')) cart.$itemAddedMsg.removeClass('show');
        })

        $(document).on('click', '.btn-booking', e => {
            let $elem = $(e.currentTarget);

            cart.getCartData();
            let params = $elem.attr('href').split('?')[1] || '';

            params = params.split('&');

            let unitData = {};

            params.forEach((val, idx) => {
                let [key, value] = val.split('=');

                unitData[key] = value;
            })

            let id = Math.floor(Math.random() * 1000000);
            let title = $elem.attr('data-title') || '';

            unitData['title'] = title;
            unitData['cartId'] = id;

            if ( ! accBookingModal.$modal.length)
            {
                cart.updateTotal(unitData['price'] || 0);
                cart.addUnitToCart(unitData);
                cart.setCartLenght();
                cart.showItemAddedMsg(unitData);
                cart.registerRemoveFromCartEvent();
            }

            accBookingModal.handle(unitData);

        });

        cart.$continueToPayment.on('click', e => {
            let $elem = $(e.currentTarget);

            if (! this.cartData.length)
            {
                let $dropdown = $elem.parents('.dropdown');
                $dropdown.find('.cart-btn').first().click();
            }
            else
            {
                var url = $elem.attr('data-href');
                window.location = url;
            }
        })


    },
    getCartData()
    {
        let cartData = functions.getCookie('cart_data') || '';
        this.cartData = cartData ? JSON.parse(cartData) : [];

        let uxCartData = functions.getCookie('ux_cart_data') || '';
        this.uxCartData = uxCartData ? JSON.parse(uxCartData) : [];
    },
    setCartData()
    {
        functions.setCookie('ux_cart_data', JSON.stringify(cart.uxCartData), .3);
        functions.setCookie('cart_data', JSON.stringify(cart.cartData), .3);
    },
    addUnitToCart(unitData)
    {
        cart.uxCartData.push(unitData);
        cart.renderCartItem(unitData);

        let data = $.extend({}, unitData);

        delete data['price'];
        delete data['title'];

        cart.cartData.push(data);

        cart.setCartData();
    },
    renderCartItem(unitData)
    {
        let $item = cart.$cartItemCopy.clone();

        $item.removeClass('cart-item-copy d-none');

        let [adults, childen] = unitData['guests'].split(',')

        $item.find('.cart-item-title').text(unitData['title']);
        $item.find('.period').text(functions.formatDate(unitData['dateFrom']) + ' - ' + functions.formatDate(unitData['dateTo']));
        $item.find('.guests-number').text((+adults + +(childen || 0)));
        $item.find('.item-price').text(exchangeRate.convertPrice((unitData['price'] || 0)) + ' ' + exchangeRate.getSymbol());
        $item.find('.remove-from-cart').attr('data-cartid', unitData['cartId']);

        if (unitData['parentId'] || null) $item.addClass('child');

        cart.$cart.find('.cart-items').append($item.clone());
    },
    renderCart()
    {
        if (cart.uxCartData.length)
        {
            cart.uxCartData.forEach((unitData, index) => {
                cart.renderCartItem(unitData);
                cart.updateTotal(unitData['price'] || 0);
            })
        }
    },
    setCartLenght()
    {
        
        if (cart.uxCartData.length && cart.$cartLengthBtns.length) 
        {
            cart.$cartLengthBtns.each(function(index, elem) {
                let $elem = $(elem);
                let hideColon = $elem.prev().css('display') == 'none';

                $elem.text((hideColon ? '' : ': ') + cart.uxCartData.length);
            })

        }
        else cart.$cartLengthBtns.text('')
    },
    registerRemoveFromCartEvent()
    {
        $('.cart').on('click', '.remove-from-cart', e => {
            let $elem = $(e.currentTarget);
            cart.getCartData();
            let idToRemove = $elem.attr('data-cartid');

            let itemToRemove = cart.uxCartData.filter(item => item.cartId == idToRemove)[0] || [];

            cart.cartData = cart.cartData.filter(item => item.cartId != idToRemove);
            cart.uxCartData = cart.uxCartData.filter(item => item.cartId != idToRemove);

            cart.updateTotal(-itemToRemove['price']);
            cart.removeFromCart(idToRemove);
            cart.setCartLenght();
            cart.setCartData();

            $(document).trigger('removed-from-cart', [idToRemove]);
            
            e.stopPropagation();
        })
    },
    updateTotal(price) 
    {
        cart.cartTotal += +price;
        cart.$total.text( exchangeRate.convertPrice(+cart.cartTotal) + ' ' + exchangeRate.getSymbol() );
    },
    setBookBtnUrl()
    {
       
        cart.$bookingBtns.attr('href', 
            cart.$bookingBtns.attr('href').split('?')[0] + '?cart_data=' + JSON.stringify(urlData)
        )
    },
    removeFromCart(id)
    {
        cart.$cart.find(`[data-cartid=${id}]`).parents('.cart-item').remove();
    },
    showItemAddedMsg(data)
    {
        cart.$itemAddedMsg.addClass('show');
        cart.$itemAddedMsg.find('.title').html(data['title'] || '');
        cart.$itemAddedMsg.find('.price-price').html(exchangeRate.convertPrice((data['price'] || 0)) + ' ' + exchangeRate.getSymbol());

        setTimeout(() => {
            cart.$itemAddedMsg.removeClass('show');
            cart.$itemAddedMsg.find('.title').html('');
            cart.$itemAddedMsg.find('.price-price').html('');
        }, 15000);
    }


}

export default cart;