import KeenSlider from 'keen-slider';

class Slider {
	constructor(elemSelector, origOpts = {}) {
		this.origOpts = origOpts;
		this.$element = $(elemSelector);
		this.$parent = this.$element.parent();
		this.origOpts.selector = origOpts.selector || '.keen-slider__slide';
		this.$pager = this.$parent.children('.keen-slider__pager');
		this.$slides = this.$element.find(this.origOpts.selector);
		
		if (! this.$pager.length)
		{
			this.$pager = this.$element.siblings().find('.keen-slider__pager');
		}

		this.$arrows = this.$parent.find('> .keen-slider__arrows');

		this.autoLoadSlides = +this.origOpts.autoLoadSlides || 2;
		if ( this.origOpts.fade ) {
			this.origOpts.slides = this.$slides.length;
			this.$element.addClass('keen-slider__fade');
		}
		this.opts = Object.assign({}, origOpts);
		this._autoplay = {
			hoverPause: false,
			dragPause: false
		};
		this.opts.created = () => {
			this.opts.autoplay && this.registerAutoplay();
			this.opts.arrows && this.registerArrows();
			this.opts.pager && this.registerPager();
			this.origOpts.created && this.origOpts.created();
			this.$element.addClass('keen-slider__created');
			this.$pager.addClass('keen-slider__created');
			// this.autoLoadSlides();
			this.autoLoadSlides > 1 && this.opts.slideChanged();
		};
		this.opts.dragStart = () => {
			this._autoplay.dragPause = true;
			this._tryAutoplay();
			this.origOpts.dragStart && this.origOpts.dragStart();
		};
		this.opts.dragEnd = () => {
			this._autoplay.dragPause = false;
			this._tryAutoplay();
			this.origOpts.dragEnd && this.origOpts.dragEnd();
		};
		this.opts.slideChanged = () => {
			setTimeout(() => {
				let activeIndex = this.instance.track.details.rel || 0;
				this.instance.slides.forEach((slide, index) => {
					let $slide = $(slide);
					$slide.toggleClass('active', index == activeIndex);
					// auto ajax load prev & next slides if applicable
					if ( index == activeIndex) {
						let autoload = [$slide];
						let counter = this.autoLoadSlides;
						while(counter > 1)
						{
							let $next = autoload[autoload.length - 1];
							let $prev = $(autoload[0].prev());

							autoload.unshift( $prev.length ? $prev : $(this.instance.slides[this.instance.slides.length - 1]) );
							autoload.push($next.next());

							counter--;
						}
						$(autoload).each(function () {
							let $slide = $(this);

							let bg = $slide.attr('data-background-image');
							bg && $slide.css('background-image', `url("${bg}")`);

							// Added for img tag
							let img = $slide.find('> [data-src]:not([src])').attr('data-src');
							img && $slide.find('> [data-src]:not([src])').attr('src', img);
						});
					}
				});
				this.$pager.length && this.$pager.find('button').each((index, btn) => {
					$(btn).toggleClass('active', index == activeIndex);
				});
				this._tryAutoplay();
				this.origOpts.slideChanged && this.origOpts.slideChanged();
			});
		};
		this.instance = new KeenSlider(this.$element[0], this.opts);
	}
	registerArrows() {
		this.$arrows.removeClass('d-none');
		this.$arrows.on('click', '[data-dir]', e => {
			e.preventDefault();
			e.stopPropagation();
			this.instance[e.currentTarget.getAttribute('data-dir')]();
		});
	}
	registerPager() {
		if ( this.$pager.length ) {
			this.$pager.find('.keen-slider__pager-buttons').html($.map(this.$slides, function (slide, i) {
				return `<button type="button" data-slide="${i}" aria-label="${i}"><span class="slide-index">${i+1}</span></button>`;
			}).join(''));
			this.$element.addClass('keen-slider__hasPager');
			this.$pager.on('click', 'button', e => {
				e.preventDefault();
				this.instance.moveToIdx(+e.currentTarget.getAttribute('data-slide'));
			});
		}
	}
	registerAutoplay() {
		if ( this.opts.pauseOnHover ) {
			this.$element.on('mouseenter', () => {
				this._autoplay.hoverPause = true;
				this._tryAutoplay();
			});
			this.$element.on('mouseleave', () => {
				this._autoplay.hoverPause = false;
				this._tryAutoplay();
			});
		}
		this._tryAutoplay();
	}
	_tryAutoplay() {
		if ( this.opts.autoplay ) {
			clearTimeout(this._autoplay.timeout);
			this._autoplay.timeout = setTimeout(() => {
				if ( !(this._autoplay.dragPause || this._autoplay.hoverPause) ) {
					this.instance.next();
				}
			}, this.opts.autoplay);
		}
	}
}

export default Slider;