import functions from "./functions.js";
let globalEventsInitiated = false;
let id = 0;
const instances = {};

const SimpleGuestsDefaults = {
	maxGuests: 100,
	maxChildren: 100,
	maxInfants: 100,
	maxExtras: 100,
	childrenAge: 16,
	infantsAge: 11,
	extrasAge: 2,
	adultMandatory: false,
	adultsLabel: 'Adults',
	childrenLabel: 'Children up to $y years',
	infantsLabel: 'Infants up to $y years',
	extrasLabel: 'Children up to $y years',
	extrasLabel: 'Children up to $y years',
	emptyText: 'Select number of guests',
	btnValueTpl: (adults, children, infants, extras, guestsText, infantsText) => {
		return `${adults + children} ${guestsText}${infants + extras ? ', ' + (infants + extras) + ' ' + infantsText : ''}`;
	},
	// to do some logic on closing dropdown, return false to prevent change event on input
	onClose: () => {
		return true;
	},
	btnApplyText: 'Apply',
	btnApplyTpl: (text) => {
		return `<button type="button" class="btn btn-sm btn-primary rounded-0 text-white w-100 simple-guests-apply">${text}</button>`;
	}
};

class SimpleGuests {

	constructor(elem, options = {}) {
		this.id = ++id;
		instances[this.id] = this;

	  	this.elem = elem;
	  	this.$elem = $(elem);
		this.$parent = $(elem).closest('.dropdown, .form-group').attr('data-igid', this.id);
		this.$controls = this.$parent.find('.simple-guests-controls');
		this.$input = this.$parent.find('[type="hidden"]');
		this.$value = this.$elem.find('.simple-guests-value');

		this.isDropdown = this.$parent.hasClass('dropdown');

		this.setOptions(options);
		this.buildControls();
		this.setValue(this.$input[0].value);
		this.setEvents();
	}

	setOptions(options) {
		if ( !functions.isObject(options) ) {
			options = {};
		}

		// set properted from options or dom attributes
		this.adultMandatory = options.adultMandatory || this.$elem.attr('data-adultMandatory') || SimpleGuestsDefaults.adultMandatory;
		this.maxGuests = this.$elem.attr('data-maxGuests');
		this.maxGuests = +(options.maxGuests > -1 ? options.maxGuests : this.maxGuests > -1 ? this.maxGuests : SimpleGuestsDefaults.maxGuests);
		this.maxChildren = this.$elem.attr('data-maxChildren');
		this.maxChildren = +(options.maxChildren > -1 ? options.maxChildren : this.maxChildren > -1 ? this.maxChildren : SimpleGuestsDefaults.maxChildren);
		this.maxInfants = this.$elem.attr('data-maxInfants');
		this.maxInfants = +(options.maxInfants > -1 ? options.maxInfants : this.maxInfants > -1 ? this.maxInfants : SimpleGuestsDefaults.maxInfants);
		this.maxExtras = this.$elem.attr('data-maxExtras');
		this.maxExtras = +(options.maxExtras > -1 ? options.maxExtras : this.maxExtras > -1 ? this.maxExtras : SimpleGuestsDefaults.maxExtras);
		this.childrenAge = this.$elem.attr('data-childrenAge');
		this.childrenAge = +(options.childrenAge > -1 ? options.childrenAge : this.childrenAge > -1 ? this.childrenAge : SimpleGuestsDefaults.childrenAge);
		this.infantsAge = this.$elem.attr('data-infantsAge');
		this.infantsAge = +(options.infantsAge > -1 ? options.infantsAge : this.infantsAge > -1 ? this.infantsAge : SimpleGuestsDefaults.infantsAge);
		this.extrasAge = this.$elem.attr('data-extrasAge');
		this.extrasAge = +(options.extrasAge > -1 ? options.extrasAge : this.extrasAge > -1 ? this.extrasAge : SimpleGuestsDefaults.extrasAge);

		// set max childs
		let maxChilds = this.maxGuests - (this.adultMandatory ? 1 : 0);
		this.maxChildren = this.maxChildren > maxChilds ? maxChilds : this.maxChildren;
		this.maxInfants = this.maxInfants > maxChilds ? maxChilds : this.maxInfants;
		this.maxExtras = this.maxExtras > maxChilds ? maxChilds : this.maxExtras;

		this.adultsLabel = options.adultsLabel || this.$elem.attr('data-adultsLabel') || SimpleGuestsDefaults.adultsLabel;
		this.childrenLabel = options.childrenLabel || this.$elem.attr('data-childrenLabel') || SimpleGuestsDefaults.childrenLabel;
		this.infantsLabel = options.infantsLabel || this.$elem.attr('data-infantsLabel') || SimpleGuestsDefaults.infantsLabel;
		this.infantsText = options.infantsText || this.$elem.attr('data-infantsText') || SimpleGuestsDefaults.infantsText;
		this.extrasLabel = options.extrasLabel || this.$elem.attr('data-extrasLabel') || SimpleGuestsDefaults.extrasLabel;
		this.emptyText = options.extrasLabel || this.$elem.attr('data-emptyText') || SimpleGuestsDefaults.emptyText;
		this.guestsText = (this.$elem.attr('data-guestsText') || 'guests');


		this.btnValueTpl = options.btnValueTpl || SimpleGuestsDefaults.btnValueTpl;
		this.onClose = options.onClose || SimpleGuestsDefaults.onClose;

		this.btnApplyText = this.$elem.attr('data-btnApplyText') || SimpleGuestsDefaults.btnApplyText;
		this.btnApplyTpl = options.btnApplyTpl || SimpleGuestsDefaults.btnApplyTpl;
	}

	buildControls() {
		this.$adults = this.buildControl('adults', this.adultsLabel);
		this.$children = this.maxChildren ? this.buildControl('children', this.childrenLabel, this.childrenAge) : null;
		this.$infants = this.maxInfants ? this.buildControl('infants', this.infantsLabel, this.infantsAge) : null;
		this.$extras = this.maxExtras ? this.buildControl('extras', this.extrasLabel, this.extrasAge) : null;
		this.$controls.append(this.$adults, this.$children, this.$infants, this.$extras, this.btnApplyTpl(this.btnApplyText));
	}

	buildControl(name, label, years) {
		return $(`
			<div class="row align-items-center simple-guests-control" data-control="${name}">
				<div class="col" data-label>${label.replace('$y', years)}</div>
				<div class="col-auto input-group align-items-center">
					<div class="input-group-prepend">
						<button tabindex="-1" class="btn btn-light" data-update="${name}" data-direction="-1" type="button">
							<i class="la la-minus"></i>
						</button>
					</div>
					<div data-value></div>
					<div class="input-group-append">
						<button tabindex="-1" class="btn btn-light" data-update="${name}" data-direction="1" type="button">
							<i class="la la-plus"></i>
						</button>
					</div>
				</div>
			</div>
		`);
	}

	getControlMinMax(name) {
		let min = name == 'adults' && this.adultMandatory ? 1 : 0;
		let max = this['max' + name[0].toUpperCase() + name.slice(1)] || this.maxGuests;
		let computedMax = this.maxGuests - (this.adults || 0) - (this.children || 0) - (this.infants || 0) - (this.extras || 0);
		computedMax = this[name] + computedMax;
		max = max > computedMax ? computedMax : max;
		return [min, max];
	}

	setValue(value) {
		value = value.split(',');
		let adults = +value[0];
		let childs = value.slice(2).reduce((count, age) => {
			count[age] = (count[age] || 0) + 1;
			return count;
		}, {});

		this.setControlValue('adults', adults);
		this.$children && this.setControlValue('children', childs[this.childrenAge]);
		this.$infants && this.setControlValue('infants', childs[this.infantsAge]);
		this.$extras && this.setControlValue('extras', childs[this.extrasAge]);
		this.setInputValue();
	}

	setControlValue(name, value = 0) {
		const $control = this['$' + name];
		const [min, max] = this.getControlMinMax(name);
		value = +value;
		if ( value < min ) {
			value = min;
		}
		else if ( value > max ) {
			value = max;
		}

		this[name] = value;
		$control.find('[data-value]').html(value);
		this.checkUpdateBtns('adults');
		this.$children && this.checkUpdateBtns('children');
		this.$infants && this.checkUpdateBtns('infants');
		this.$extras && this.checkUpdateBtns('extras');
	}

	checkUpdateBtns(name) {
		const value = this[name];
		const [min, max] = this.getControlMinMax(name);
		this.$controls.find(`[data-update="${name}"][data-direction="-1"]`).prop('disabled', value == min);
		this.$controls.find(`[data-update="${name}"][data-direction="1"]`).prop('disabled', value == max);
	}

	setEvents() {
		// react on "outer input set value"
		this.$input.on('change', () => {
			!this.settingValue && this.setValue(this.$input[0].value);
		});

		this.$controls.on('click', '[data-update]', e => {
			const $btn = $(e.currentTarget);
			const name = $btn.attr('data-update');
			const value = this[name];
			const direction = +$btn.attr('data-direction');
			this.setControlValue(name, value + direction);
			this.setInputValue();
		});

		// prevent closing dropdown when clicking inside
		this.$parent.find('.simple-guests-controls.dropdown-menu').on('click', e => {
			e.preventDefault();
		});

		this.$parent.find('.simple-guests-apply').on('click', () => {
			this.$elem.dropdown('hide');
		});

		if ( !globalEventsInitiated ) {
			globalEventsInitiated = true;
			
			// ke kaco - doesnt work as jquery.on ...
			document.addEventListener('hide.bs.dropdown', e => {
				const $btn = $(e.target);
				const $dropdown = $btn.closest('.dropdown');
				const igid = $dropdown.attr('data-igid');

				if ( $btn.hasClass('show') && igid ) {
					instances[igid].close();
				}
			});
		}
	}

	setInputValue() {
		const value = [
			this.adults,
			(this.$children ? this.children : 0) + (this.$infants ? this.infants : 0) + (this.$extras ? this.extras : 0),
			...(this.$children ? Array(this.children).fill(this.childrenAge) : []),
			...(this.$infants ? Array(this.infants).fill(this.infantsAge) : []),
			...(this.$extras ? Array(this.extras).fill(this.extrasAge) : []),
		];
		this.$input[0].value = value.join(',');
		this.$value.length && this.setBtnValue();
		this.settingValue = true;
		!this.isDropdown && this.$input.trigger('change');
		this.settingValue = false;
	}

	setBtnValue() {
		this.$value.html( ! this.adults ? this.emptyText : SimpleGuestsDefaults.btnValueTpl(this.adults, this.children, this.infants, this.extras, this.guestsText, this.infantsText));
	}
	// for public use
	getValue() {
		return this.$input[0].value;
	}
	// only if dropdown version
	close() {
		typeof this.onClose === 'function' && this.onClose() && this.$input.trigger('change');
	}

}

export {
	SimpleGuests,
	SimpleGuestsDefaults
};
