if (window.wp && window.wp.ajax)
{

    const { registerBlockType } = window.wp.blocks;

    const {
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;

    const {
        PanelBody,
        SelectControl,
        TextControl,
    } = window.wp.components;

    registerBlockType('custom-gutenberg/card-park-info', {
        // built-in attributes
        title: 'Card Park info',
        description: 'Custom Card Park info',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            icon: {
                type: 'string',
                default: 'la-landmark',
            },
            link: {
                type: 'string',
                default: '',
            },
            title: {
                type: 'string',
                default: ''
            },
            text: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes })
        {

            const {
                icon,
                link,
                title,
                text,
                viewMore,
            } = attributes;

            // custom functions
            function onIconChange(icon)
            {
                setAttributes({ icon: icon });
            }

            function onLinkChange(link)
            {
                setAttributes({ link: link });
            }

            function onTitleChange(title)
            {
                setAttributes({ title: title });
            }

            function onTextChange(text)
            {
                setAttributes({ text: text });
            }

            function onViewMoreChange(viewMore)
            {
                setAttributes({ viewMore: viewMore });
            }

            return ([
                <InspectorControls style={{ marginBottom: '40px' }}>
                    <PanelBody title={'Settings'}>
                        <p><strong>Icon</strong></p>
                        <SelectControl
                            value={icon}
                            options={[
                                { label: 'Landmark', value: 'la-landmark' },
                                { label: 'Archway', value: 'la-archway' },
                                { label: 'Calendar', value: 'la-calendar' },
                            ]}
                            onChange={onIconChange}
                        />
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={link} onChange={onLinkChange}></TextControl>
                        <br />
                        <p><strong>Title</strong></p>
                        <TextControl value={title} onChange={onTitleChange}></TextControl>
                        <br />
                        <p><strong>Text</strong></p>
                        <TextControl value={text} onChange={onTextChange}></TextControl>
                        <br />
                        <p><strong>View more text</strong></p>
                        <TextControl value={viewMore} onChange={onViewMoreChange}></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <a class="card park-info-card">
                    <div class="card-body text-center">
                        <i class="las la-landmark display-3 text-warning mb-1"></i>
                        <RichText
                            key="editable"
                            tagName="h4"
                            className="card-title"
                            placeholder="TITLE"
                            value={title}
                            onChange={onTitleChange} />
                        <RichText
                            key="editable"
                            tagName="p"
                            className="card-text"
                            placeholder="TEXT"
                            value={text}
                            onChange={onTextChange} />
                        <RichText
                            key="editable"
                            tagName="div"
                            className="view-more d-flex justify-content-center align-items-center"
                            placeholder="VIEW MORE"
                            value={viewMore}
                            onChange={onViewMoreChange} />
                    </div>
                </a>
            ]
            );
        },

        save({ attributes })
        {

            const {
                icon,
                link,
                title,
                text,
                viewMore,
            } = attributes;

            return (
                <a href={link} className="card park-info-card">
                    <div className="card-body text-center">
                        <i className={'las display-3 text-warning mb-1 ' + icon}></i>
                        <h4 className="card-title">{title}</h4>
                        <p className="card-text px-lg-4 px-3">
                            {text}
                        </p>
                        <div className="view-more d-flex justify-content-center align-items-center">
                            <div className="">{ viewMore }</div>
                            <i className="las la-angle-right"></i>

                        </div>
                    </div>
                </a>
            );
        },
    });
}