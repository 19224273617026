import functions from "./functions.js";
const recaptcaPublicKey = '6LeJSbInAAAAAEeWvkbvpow-junws70tsPvQuNFc';

const review = {
    init() {
        let $form = $('.review-form');
        if ( ! $form[0]) return;

        let lang = functions.getLang();

        let $msg = $('.review-msg');
        let data = $('[data-review-data]').data('review-data');
        data.language = functions.getLang();
        data.items = {};
        $("[data-review-item-id]").each((index, item) => {
            let id = $(item).attr('data-review-item-id');
            let $input = $(item).find('input[name="rating-' + id + '"]');
            data.items[id] = {
                unitId: id
            };

            $(item).find('.rating').on('mouseover', function(e) {
                let $elem = $(this);
                if($elem.find('.star').hasClass('red-star')) $(this).find('.star').removeClass('red-star');
            })

            $(item).find(".rating .star").on('click', function (e) {
                let $star = $(e.currentTarget);
                let value = $star.attr('data-value');
                $input.attr('value', value);
            });
        });


        $('form').on('submit', e => {
            
            e.preventDefault();
            let complete = true;
            $.each(data.items, function (id, item) {
                let $rating = $('input[name="rating-' + id + '"]');
                let $review = $('textarea[name="review-' + id + '"]');

                let rating = $rating.val();
                let review = $review.val();

                $rating.toggleClass('input-empty', ! rating).siblings('.invalid-feedback').toggleClass('d-block', ! rating)
                    .siblings('.rating').find('.star').toggleClass('red-star', ! rating);
                $review.toggleClass('input-empty', ! review).siblings('.invalid-feedback').toggleClass('d-block', ! review);

                if ( ! ( rating && review)) complete = false;


                data.items[id].rating = rating;
                data.items[id].review = review;
            });

            if ( ! complete) return ;
            grecaptcha.ready(function() {
                grecaptcha.execute(functions.getRecaptchaKey(), {action: 'review'}).then(function(token) {
                    data.gRecaptchaToken = token;
                    
                    $.post('/services/review/', data).then(response => {
                        $msg.removeClass('d-none');
                        $msg.find('.review-success').toggleClass('d-none', ! response.status );
                        $msg.find('.review-error').toggleClass('d-none', response.status );
                        $form.find("button[type='submit']").addClass('disabled');

                        $('#review-response-modal').modal('show');
                        $(".response-output").html(response.data);

                        $('.contact-btn').removeClass('d-none');
                        $('.contact-spinner').removeClass('d-flex');
                        $('.contact-spinner').addClass('d-none');
                        //setTimeout(() => { $('#contact-modal').modal('hide') }, 4000);

                    });
                });
            });
        });
    }
}



$(() => {
    review.init();
});