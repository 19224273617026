if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        InnerBlocks,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        TextControl,
    } = window.wp.components;
    


    registerBlockType('custom-gutenberg/fact', {
        // built-in attributes
        title: 'Fact',
        description: 'Fact',
        icon: '',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            title: {
                type: 'string',
                default: 'Interesting Facts'
            },
            text: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                title
            } = attributes;


            function onTextChange(text) {
                setAttributes({title: text});
            }


            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTextChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <div class="facts">
                    <div class="header">
                        <i class="las la-exclamation"></i>
                       {title}
                    </div> 
                    <div class="card"> 
                        <div class="card-body">
                            <InnerBlocks />
                        </div>
                    </div> 
                </div>
            ]
            );
        },

        save({ attributes }) {

            const {
                title
            } = attributes;

            return (
                <div className="facts">
                    <div className="header">
                        <i className="las la-exclamation"></i>
                       {title}
                    </div> 
                    <div className="card"> 
                        <div className="card-body">
                            <InnerBlocks.Content />
                        </div>
                    </div> 
                </div>
            );
        },
    });
}