import { getFormData, getRecaptchaKey, getLang } from "./functions";
import exchangeRate from "./exchangeRate";


const giftCard = {

    $form: null,
    params: {},
    togglable: ['rAddress', 'rPlace', 'rZip'],
    init()
    {
        giftCard.$form = $('#gift-card-booking');

        if (! giftCard.$form.length) return;

        giftCard.$form.on('change', '#sendToAddress', function() {
            console.log(this.checked);
            if (this.checked)
            {
                giftCard.togglable.forEach((value) => {
                    let $elem = giftCard.$form.find(`[name="${value}"]`);
                    let $parent = $elem.parents('.tooglable-parent');

                    $elem.prop('required', true);
                    $parent.removeClass('d-none');

                });
            }
            else
            {
                giftCard.togglable.forEach((value) => {
                    let $elem = giftCard.$form.find(`[name="${value}"]`);
                    let $parent = $elem.parents('.tooglable-parent');

                    $elem.prop('required', false);
                    $parent.addClass('d-none');

                });
            }
        })

        giftCard.$form.find('.gift-card-price-item').each((index, elem) => {
            let $elem = $(elem);
            let $price = $elem.find('[data-price]');
            let price = $price.attr('data-price');

            let val = $elem.find('[value]').attr('value');
            val = JSON.parse(val);
            val.forEach(function (value, i) {
                val[i]['total'] = exchangeRate.convertPrice(+value['total']);
            });
            $elem.find('[value]').attr('value', JSON.stringify(val));
            $price.html(exchangeRate.convertPrice(+price) + ' ' + exchangeRate.getSymbol());
        });


        giftCard.$form.on('submit', e => {
            e.preventDefault();

            giftCard.$form[0].reportValidity();

            giftCard.params = getFormData(giftCard.$form);
            giftCard.params.paymentType = 'bankTransfer';
            giftCard.params.status = 'Reservation';
            giftCard.params.requestId = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);

            if ( ! giftCard.params.sendToAddress)
            {
                let calc = JSON.parse(giftCard.params.calc);
                delete calc[1];
                
                giftCard.params.calc = JSON.stringify(calc);
                delete giftCard.params.rAddress;
                delete giftCard.params.rPlace;
                delete giftCard.params.rZip;
            }

            giftCard.params.giftCard = 1;
            
            grecaptcha.ready(function ()
            {
                grecaptcha.execute(getRecaptchaKey(), { action: 'contact' }).then(function (token)
                {
                    giftCard.params.gRecaptchaToken = token;
                    giftCard.params.language = getLang();
                    $.post('/services/booking/', giftCard.params).then(response =>
                    {
                        $('.request-response').removeClass('d-none').html(response);
                        $('.contact-btn').removeClass('d-none');
                        $('.contact-spinner').addClass('d-none');
                        $('.contact-spinner').removeClass('d-flex');

                        giftCard.$form.find('.hide-on-send').addClass('d-none');

                        setTimeout(() =>
                        {
                            $(".request-response").addClass('d-none').html('');
                            giftCard.$form.find('.hide-on-send').removeClass('d-none');
                            e.currentTarget.reset();

                        }, 60000);
                    });
                });
            });
        });
    }
}

export default giftCard;