import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;

    const {useBlockProps, useInnerBlocksProps } = window.wp.blockEditor;

    const { 
        InnerBlocks,
    } = window.wp.editor;

    registerBlockType('custom-gutenberg/home-modal', {
        // built-in attributes
        title: 'Home modal',
        description: 'Home modal',
        icon: '',
        category: 'custom-containers',
        example: {},

        // built-in functions
        edit({ attributes, setAttributes, className }) {
            const blockProps = useBlockProps( {} );
            const innerBlocksProps = useInnerBlocksProps( blockProps, {} );
            const {
                classes
            } = attributes;

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});


            innerBlocksProps.className = classes;
            return (
                innerBlocksProps.children
            );
        },

        save({ attributes }) {

            const {
                classes
            } = attributes;

            return (
                <div className="modal modal-home fade" id="homeSearchModal" tabindex="-1" data-bs-backdrop="false" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-fullscreen-lg-down">
                    <div className="modal-content border-0 rounded-0 px-2">
                        <div className="modal-header d-lg-none d-block border-0 mt-1">
                            <button type="button" className="btn-close d-flex" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div className="modal-body">
                        <div className="home-search-form search-for">
                                <InnerBlocks.Content />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            );
        },
    });
}