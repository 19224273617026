if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        TextControl,
    } = window.wp.components;

    let text = 'View more';

    registerBlockType('custom-gutenberg/cta', {
        // built-in attributes
        title: 'Cta',
        description: 'Cta',
        icon: '',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            text: {
                type: 'string',
                default: 'View more'
            },
            link: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                text,
                link
            } = attributes;


            function onTextChange(text) {
                setAttributes({text: text});
            }

            function onLinkChange(text) {
                setAttributes({link: text});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <p><strong>Text</strong></p>
                        <TextControl value={ text } onChange={ onTextChange }></TextControl>
                        <br/>
                        <p><strong>Text</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <button class="btn btn-primary text-white py-1 text-uppercase fw-semibold">
                    {text}
                </button>
            ]
            );
        },

        save({ attributes }) {

            const {
                text,
                link
            } = attributes;

            let elemType = 'div';

            if (link) elemType = 'a';

            const ElemTag = `` + elemType + ``;

            return (
                <ElemTag href={link}>
                    <button class="btn btn-primary text-white py-1 text-uppercase fw-semibold">
                        {text}
                    </button>
                </ElemTag>
            );
        },
    });
}