import _default from "keen-slider";

if (window.wp && window.wp.ajax)
{

    const { registerBlockType } = window.wp.blocks;

    const {
        InnerBlocks
    } = window.wp.editor;

    const ALLOWED_BLOCKS = ['custom-gutenberg/hero-slide'];

    registerBlockType('custom-gutenberg/hero-slider', {
        // built-in attributes
        title: 'Hero Slider',
        description: 'Custom Hero Slider',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // built-in functions
        edit()
        {

            const MY_TEMPLATE = [
                ['custom-gutenberg/hero-slide', {}],
            ];


            return (
                <div class="hero position-relative">
                    <div id="hero-slider" class="keen-slider" style={{ opacity: '1' }}>
                        <InnerBlocks allowedBlocks={ALLOWED_BLOCKS} template={MY_TEMPLATE} />
                    </div>
                    <div class="keen-slider__pager">
                        <div class="keen-slider__pager-buttons"></div>
                    </div>
                </div>
            );
        },

        save()
        {
            return (
                <div className="home position-relative">
                    <div id="home-slider" className="keen-slider">
                        <InnerBlocks.Content />
                    </div>
                    <div className="keen-slider__pager">
                        <div className="keen-slider__pager-buttons"></div>
                    </div>
                    <div className="keen-slider__arrows d-lg-flex container">
                        <div data-dir="prev" className="bg-transparent rounded-circle d-flex align-items-center justify-content-center border border-2 border-white">
                            <i className="las la-angle-left fs-2 text-white"></i>
                        </div>
                        <div data-dir="next" className="bg-transparent rounded-circle d-flex align-items-center justify-content-center border border-2 border-white">
                            <i className="las la-angle-right fs-2 text-white"></i>
                        </div>
                    </div>
                </div>
            );
        },
    });
}