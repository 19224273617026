import exchangeRate from "./exchangeRate";
import functions from './functions.js';
import { getFormData, getRecaptchaKey } from "./functions";

const parkTickets = {

    $form: null,
    isTransferModal: false,
    params: {},
    togglable: ['rAddress', 'rPlace', 'rZip'],
    init()
    {
        parkTickets.$form = $('#tickets-booking');

        if (! parkTickets.$form.length) return;

        parkTickets.isTransferModal = parkTickets.$form.hasClass('transfers-modal');

        parkTickets.$form.find('.quantity').each((index, quantity) => {
            let $elem = $(quantity);

            if ($elem.attr('data-min'))
            {
                $elem.find('input').val(+$elem.attr('data-min'));
                $elem.find('label').html(+$elem.attr('data-min'));
            }
        } )

        parkTickets.$form.find('[data-price]').each((index, elem) => {
            let $elem = $(elem);
            let price = $elem.attr('data-price');
            $elem.html(`<span class="price-price" data-total="">` + exchangeRate.convertPrice(price)  + '</span>' + ' ' + exchangeRate.getSymbol());
        });



        $('[data-direction]').on('click', e => {
            let $elem = $(e.currentTarget);

            let step = +$elem.attr('data-direction');

            let currVal = +$elem.siblings('input').val();

            let price = +$elem.parents('.tickets-item').find('.price-price').attr('data-total');

            let min = 0;
            let max = 40;

            let $quantity = $elem.parents('.quantity');

            if ($quantity.attr('data-min')) min = +$quantity.attr('data-min');
            if ($quantity.attr('data-max')) max = +$quantity.attr('data-max');

            let newVal = currVal + step;

            newVal = newVal > max ? max : newVal < min ? min : newVal;
            $elem.siblings('input').val(newVal);

            $elem.siblings('label').html(newVal);

            let $btnBooking = $elem.parents('.tickets-item').find('.btn-booking');

            if (! newVal || ! parkTickets.params.dateFrom || newVal == min || newVal == max) $btnBooking.addClass('disabled');
            else $btnBooking.removeClass('disabled');

            if ($btnBooking.attr('href')) {
                
                let params = $btnBooking.attr('href').split('?')[1] || '';
                params = params.split('&');

                let unitData = {};

                params.forEach((val, idx) => {
                    let [key, value] = val.split('=');
    
                    unitData[key] = value;
                })

                unitData.quantity = newVal;
                unitData.price = price;

                let guests = '1,0,0';

                if (parkTickets.isTransferModal)
                {
                    guests = unitData.quantity + ',0,0';
                    unitData.quantity = 1;
                }

                let stringData = {
                    unitId: unitData.unitId,
                    dateFrom: unitData.dateFrom,
                    dateTo: unitData.dateTo,
                    quantity: unitData.quantity,
                    guests: guests,
                    price: unitData.price,
                }

                parkTickets.setBookBtnHref($btnBooking, stringData);
            }
        })

        
        parkTickets.$form.find('[name="guests"]').on('change', e => {
            parkTickets.calculation()
        })
        
 

        parkTickets.$form.find('[name="dates"]').on('change', e => {
            parkTickets.calculation()
        })

        parkTickets.$form.on('submit', e => {
            e.preventDefault();

            parkTickets.$form[0].reportValidity();

            parkTickets.params = getFormData(parkTickets.$form);
            parkTickets.params.paymentType = 'bankTransfer';
            parkTickets.params.status = 'Reservation';
            parkTickets.params.requestId = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);

            if ( ! parkTickets.params.sendToAddress)
            {
                let calc = JSON.parse(parkTickets.params.calc);
                delete calc[1];
                
                parkTickets.params.calc = JSON.stringify(calc);
                delete parkTickets.params.rAddress;
                delete parkTickets.params.rPlace;
                delete parkTickets.params.rZip;
            }

            parkTickets.params.parkTickets = 1;
            
            grecaptcha.ready(function ()
            {
                grecaptcha.execute(getRecaptchaKey(), { action: 'contact' }).then(function (token)
                {
                    parkTickets.params.gRecaptchaToken = token;

                    $.post('/services/booking/', parkTickets.params).then(response =>
                    {
                        $('.request-response').removeClass('d-none').html(response);
                        $('.contact-btn').removeClass('d-none');
                        $('.contact-spinner').addClass('d-none');
                        $('.contact-spinner').removeClass('d-flex');

                        parkTickets.$form.find('.hide-on-send').addClass('d-none');

                        setTimeout(() =>
                        {
                            $(".request-response").addClass('d-none').html('');
                            parkTickets.$form.find('.hide-on-send').removeClass('d-none');
                            e.currentTarget.reset();

                        }, 60000);
                    });
                });
            });
        });

        $('.btn-booking').on('click', e => {
            let $elem = $(e.currentTarget);
            let $parent  = $elem.parents('.tickets-item');
            let $quantity = $parent.find('.quantity');

            if ($quantity.attr('data-min'))
            {
                $quantity.find('input').val(+$quantity.attr('data-min'));
                $quantity.find('label').html(+$quantity.attr('data-min'));
            }
            else
            {
                $quantity.find('input').val(0);
                $quantity.find('label').html(0);
            }

           if (! parkTickets.isTransferModal)  $elem.addClass('disabled');
        })
    },
    calculation()
    {
        let params = getFormData(parkTickets.$form);

        params.dateFrom = params.dates;
        params.dateTo = params.dates;

        if (parkTickets.isTransferModal)
        {
            if (! params.dates || params.guests == '0,0') return;
        }


        parkTickets.params.dateFrom = params.dateFrom;
        parkTickets.params.dateTo = params.dateTo;
        parkTickets.params.guests = '1,0,0';
        parkTickets.params.publicId = params.publicId;
        parkTickets.params.objectGroupId = params.objectGroupId;

        parkTickets.$form.find('.quantity').each((index, quantity) => {
            let $elem = $(quantity);

            if ($elem.attr('data-min'))
            {
                $elem.find('input').val(+$elem.attr('data-min'));
                $elem.find('label').html(+$elem.attr('data-min'));
            }
            else
            {
                $elem.find('input').val(0);
                $elem.find('label').html(0);
            }
        } )
        $('.tickets-item').find('.btn-booking').addClass('disabled');

        let oldCurr = functions.getCookie('currencyIdTo');
        functions.setCookie('currencyIdTo', 1);

        $.post('/services/calc/', parkTickets.params).then(response =>
        {
            functions.setCookie('currencyIdTo', oldCurr);
            response = JSON.parse(response);
            let data = response.data;

            Object.entries(data).forEach(unit => {
                try {
                    const unitId = unit[0];
                    let unitData = unit[1];
                    const $unit = $('.tickets-item[data-unitid=' + unitId + ']');
                    
                    const $btnBooking = $unit.find('.btn-booking');

                    let transferValid = true;

                    if ($unit.length && parkTickets.isTransferModal)
                    {
                        let persons = +params.guests.split(',')[0] || 1;
                        let min = +$unit.find('.quantity').attr('data-min') || 0;
                        let max = +$unit.find('.quantity').attr('data-max') || 1000;

                        if (persons < min || persons > max) transferValid = false;
                    }
                    
                    if (transferValid && unitData.calc && unitData.calc.items && unitData.calc.items.length) 
                    {
                        $unit.find('.error-msg').removeClass('visible');
                        setTimeout(() => {

                            $unit.find('.quantity-wrap').addClass('visible');
                            $unit.find('.has-price').removeClass('hidden');
                            let hrefParams = $btnBooking.attr('href').split('?')[1] || '';
                            hrefParams = hrefParams.split('&');
                            
                            unitData.calc.items.sort((a,b) => a.itemTotal - b.itemTotal);

                            let pcItem = unitData.calc.items[0];

                            $unit.find('.price-price').attr('data-total', pcItem.itemTotal);
                            $unit.find('.price-price').html(exchangeRate.convertPrice(pcItem.itemTotal));

                            let unitParams = {};

                            hrefParams.forEach((val, idx) => {
                                let [key, value] = val.split('=');
                
                                unitParams[key] = value;
                            })

                            let stringData = {
                                unitId: unitParams.unitId,
                                dateFrom: parkTickets.params.dateFrom,
                                dateTo: parkTickets.params.dateTo,
                            }

                            if (parkTickets.isTransferModal)
                            {
                                let persons = +params.guests.split(',')[0] || 1;
                                stringData.guests = params.guests;
                                stringData.quantity = persons;
                                stringData.price = pcItem.itemTotal;
                                $btnBooking.removeClass('disabled')
                            }

                            parkTickets.setBookBtnHref($btnBooking, stringData);

                        }, 330);
                    }
                    else 
                    {
                        $unit.find('.has-price').addClass('hidden');

                        setTimeout(() => {
                            $unit.find('.error-msg').addClass('visible');
                        }, 330);
                    }
                } catch (error) {
                    console.log(error);
                }
            })
        });
    },
    setBookBtnHref($btnBooking, data)
    {
        let queryString = Object.entries(data).map(s => `${s[0]}=${s[1]}`).flat().join('&');
        $btnBooking.attr('href',
            $btnBooking.attr('href').split('?')[0] +
            '?' + queryString
        );
    }
}

export default parkTickets;