import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;

    const {useBlockProps, useInnerBlocksProps } = window.wp.blockEditor;

    const { 
        InnerBlocks,
    } = window.wp.editor;

    registerBlockType('custom-gutenberg/column', {
        // built-in attributes
        title: 'Column',
        description: 'Custom column',
        icon: '',
        category: 'custom-containers',
        example: {},

        // built-in functions
        edit({ attributes, setAttributes, className }) {
            const blockProps = useBlockProps( {} );
            const innerBlocksProps = useInnerBlocksProps( blockProps, {} );
            const {
                classes
            } = attributes;

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});


            innerBlocksProps.className = classes;
            return (
                <div class={ classes }>
                    <InnerBlocks />
                </div>
            );
        },

        save({ attributes }) {

            const {
                classes
            } = attributes;

            return (
                <div class={ classes ? classes : 'col-lg' }>
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}