import _default from "keen-slider";

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        InnerBlocks
    } = window.wp.editor;

    const ALLOWED_BLOCKS = ['custom-gutenberg/pages-slide'];

    registerBlockType('custom-gutenberg/pages-slider', {
        // built-in attributes
        title: 'Pages Slider',
        description: 'Custom Pages Slider',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                clientId = Math.floor(Math.random() * 100)
            } = attributes;

            setAttributes({clientId: clientId});

            const MY_TEMPLATE = [
                [ 'custom-gutenberg/pages-slide', {} ],
            ];

            return (
                <div class="pages-slider position-relative">
                    <div id={ "pages-slider-" + clientId } class="een-slider" style={ { opacity: '1' } }>
                        <InnerBlocks allowedBlocks={ ALLOWED_BLOCKS } template={ MY_TEMPLATE } />
                    </div>
                    <div class="keen-slider__pager">
                        <div class="keen-slider__pager-buttons"></div>
                    </div>
                </div>
            );
        },

        save({ attributes }) {

            const {
                clientId
            } = attributes;

            return (
                <div className="pages-slider position-relative">
                    <div id={ "pages-slider-" + clientId } className="keen-slider">
                        <InnerBlocks.Content />
                    </div>
                    <div className="keen-slider__pager">
                        <div className="keen-slider__pager-buttons"></div>
                    </div>
                </div>
            );
        },
    });
}