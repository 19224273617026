if (window.wp && window.wp.ajax)
{

    const { registerBlockType } = window.wp.blocks;

    const {
        RichText,
        InspectorControls,
        MediaUpload,
        InnerBlocks
    } = window.wp.editor;

    const {
        PanelBody,
        IconButton,
        TextControl,
        Button
    } = window.wp.components;

    registerBlockType('custom-gutenberg/review-slide', {
        // built-in attributes
        title: 'Review Slide',
        description: 'Custom Review Slide',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
        },

        // built-in functions
        edit({ attributes, setAttributes })
        {

            const {
                link,
                backgroundImage,
                title,
                description,
                period,
                viewMore
            } = attributes;

            return (
                <div className="review-slide">
                    <InnerBlocks />
                </div>
            );
        },

        save({ attributes })
        {
            const {
                link,
                backgroundImage,
                title,
                description,
                period,
                viewMore
            } = attributes;

            return (
                <div className="review-slide keen-slider__slide">
                    <div className="container slider-content d-flex">
                        <div className="row justify-content-center flex-1 align-items-center">
                            <div className="col-lg-8">
                                <InnerBlocks.Content />
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    });
}